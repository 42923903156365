<div class="container">
  <button (click)="addStructInstance()" *ngIf="this.data && !this.data.value" class="btn btn-primary">Add</button>

  <div *ngIf="currentResource" class="d-flex align-items-center">
    <app-text-area-field [data]="this.currentResourceFieldComponents[0].data" class="flex-grow-1"></app-text-area-field>
    <button (click)="openAudioModal()" [ngbPopover]="popoverContent"
            class="btn ml-2" triggers="hover"
            type="button">
      <span *ngIf="isAudioFilledIn()" class="bi bi-mic-fill"></span>
      <span *ngIf="!isAudioFilledIn()" class="bi bi-mic"></span>
    </button>
  </div>

  <ng-template #audioModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Audio File</h4>
    </div>
    <div class="modal-body">
      <app-audio-field [data]="this.currentResourceFieldComponents[1].data"></app-audio-field>
    </div>
    <div class="modal-footer">
      <button (click)="modal.close('Save click')" class="btn btn-primary" type="button">Close</button>
    </div>
  </ng-template>

  <ng-template #popoverContent>
    Select an audio file<br>{{ getAudioName() }}
  </ng-template>
</div>
