export const environment = {
  production: false,
  authServerHost: 'https://api.max.dev.gametailors.com/auth/',
  casHost: 'https://api.max.dev.gametailors.com/cas',
  // TODO Do not hardcode this
  dataPackage: 'dev_datapackage-84b53537-7d56-4a9e-a806-4da35da6ed7b',
  defaultGame: 'max',
  aspectRatio: { width: 1920, height: 1080 },
  // playGameUrl: 'https://speel.max.dev.gametailors.com/'
  playGameUrl: 'https://webgl.euduca.nl/demo/',

  version_latestBuildNumber: '743',
  version_latestCommitHash: 'b32993334fdc1600a30adc378bf0cc4a6e6f01e1',
  version_latestDate: '18/09/2024 15:23',
};
