import { Component, EventEmitter, Input, Output } from '@angular/core';
import fieldData, { FieldData } from '../../../../models/data/FieldData';
import { DataService } from '../../../../_services/data-management/data.service';
import { AlertService } from '../../../../_services/UI-elements/alert-service';
import { BootstrapClass } from '../../../../models/types/BootstrapClass';

@Component({
  selector: 'app-color-field',
  templateUrl: './color-field.component.html',
  styleUrls: ['./color-field.component.scss'],
})
export class ColorFieldComponent {
  @Input({
    transform: (value: FieldData<string | string[] | unknown>) => fieldData.transform(value),
  })
  data: FieldData<string> | undefined;

  @Output() colorChanged: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private dataService: DataService,
    private alertService: AlertService,
  ) {}

  update(color: string) {
    if (!this.data) throw new Error('Data not found');
    this.data.value = color;
    this.colorChanged.emit(color);
    this.dataService.updateFieldValue(this.data.dataInstanceUid, this.data.fieldId, color).then();
    this.alertService.showAlert('Updated ' + this.data.name + '...', BootstrapClass.INFO);
  }
}
