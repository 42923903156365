import { DataService } from '../../_services/data-management/data.service';
import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';
import { SearchService } from '../../_services/UI-elements/search.service';
import { HTTPRequestService } from '../../_services/data-management/HTTP-request.service';

@Component({
  selector: 'app-home-screen',
  templateUrl: './home-screen.component.html',
  styleUrls: ['./home-screen.component.scss'],
})
export class HomeScreenComponent {
  /*
   * The home screen component is the first component that is loaded when the app is opened (unless you are not logged in).
   * It only holds the navigation bar where you can access the overview list of all resources.
   * Via routing the correct list and editor components are loaded.
   */

  protected readonly resourceStructs: string[] = [];
  protected readonly versions = [
    `Frontend : Build#${environment.version_latestBuildNumber || '0'} [${(environment.version_latestCommitHash || 'abcdefg').slice(0, 7)}] - ${environment.version_latestDate || 'Unknown'}`,
  ];

  constructor(
    dataService: DataService,
    requestService: HTTPRequestService,
    protected searchService: SearchService,
  ) {
    this.resourceStructs = dataService.getResourceStructs();

    //Set the theme based on the user's system preference
    document.documentElement.setAttribute(
      'data-bs-theme',
      localStorage.getItem('theme') ?? (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'),
    );

    requestService.getBackendVersionInformation().subscribe((data) => {
      this.versions.push(
        `Backend  : ${data.version.tag.replace('-SNAPSHOT', '')}#${data.version.buildNumber} [${data.version.commitHash.slice(0, 7)}] - ${data.version.timestamp}`,
      );
    });
  }

  showSearch() {
    this.searchService.show();
  }

  toggleDarkMode() {
    const currentTheme = document.documentElement.getAttribute('data-bs-theme');
    const newTheme = currentTheme === 'dark' ? 'light' : 'dark';
    document.documentElement.setAttribute('data-bs-theme', newTheme);
    localStorage.setItem('theme', newTheme);
  }
}
