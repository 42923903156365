import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { DynamicFieldService } from './dynamic-field.service';
import { StructType } from '../../models/schema/StructType';
import { DataInstance } from '../../models/data/DataInstance';
import { FieldData } from '../../models/data/FieldData';
import { AlertService } from '../UI-elements/alert-service';
import { BootstrapClass } from '../../models/types/BootstrapClass';
import { lastValueFrom } from 'rxjs';
import { HTTPRequestService } from './HTTP-request.service';

export interface SimpleStructInstance {
  uid: string;
  fields: Record<string, FieldData<unknown>>;
  structType: string;
}

@Injectable()
export class SubcomponentService {
  constructor(
    private dataService: DataService,
    private fieldFactory: DynamicFieldService,
    private alertService: AlertService,
    private httpRequestService: HTTPRequestService,
  ) {}

  showAlert(name: string) {
    this.alertService.showAlert('Updated ' + name + '...', BootstrapClass.INFO);
  }

  async addSubStruct(
    parentDataInstanceUid: string,
    parentField: string,
    valueOfParentFieldValue: unknown,
    structType: StructType,
  ): Promise<SimpleStructInstance> {
    const subStruct = await this.dataService.initStruct(structType.typeId);
    const subStructData: Record<string, FieldData<unknown>> = {};

    for (const field of structType.fields) {
      if (!field.required) continue;
      const fieldIndex = subStruct.fieldValues.findIndex((fieldValue) => fieldValue.field === field.fieldId);
      subStructData[field.fieldId] = this.fieldFactory.getData(field, subStruct.uid, subStruct.fieldValues[fieldIndex].value);
    }

    // Add the substruct to the parent data instance
    if (Array.isArray(valueOfParentFieldValue)) valueOfParentFieldValue.push(subStruct.uid);
    else valueOfParentFieldValue = subStruct.uid;

    // Update the parent data instance
    await this.dataService.updateFieldValue(parentDataInstanceUid, parentField, valueOfParentFieldValue);
    this.showAlert(structType.name);

    return { uid: subStruct.uid, fields: subStructData, structType: structType.typeId };
  }

  async convertArrayData(data: FieldData<string[]>): Promise<SimpleStructInstance[]> {
    return await Promise.all(data.value.map(async (reference) => await this.convertData(reference)));
  }

  async convertData(referenceUid: string): Promise<SimpleStructInstance> {
    const dataInstance = await this.dataService.getDataInstance(referenceUid);
    if (!dataInstance) throw new Error('Data instance not found');

    const subStructData: Record<string, FieldData<unknown>> = {};
    const structType = await lastValueFrom(this.httpRequestService.getStructType(this.dataService.currentGameId, dataInstance.dataType));

    for (const field of structType.fields) {
      const fieldValue = dataInstance.fieldValues.find((fv) => fv.field === field.fieldId);
      subStructData[field.fieldId] = this.fieldFactory.getData(field, dataInstance.uid, fieldValue?.value ?? field.defaultValue);
    }
    return { uid: dataInstance.uid, fields: subStructData, structType: structType.typeId };
  }

  // Remove field from array and from the data instance
  async removeSubStruct(
    subStructData: { uid: string; fields: Record<string, FieldData<unknown>> },
    fieldList: { uid: string; fields: Record<string, FieldData<unknown>> }[],
    parentDataInstance: DataInstance,
    fieldId: string,
  ) {
    // Delete subfield from fieldList
    const index = fieldList.indexOf(subStructData);
    fieldList.splice(index, 1);

    // Delete subfield reference from parent data instance
    const updatedValue = parentDataInstance.fieldValues.find((fieldValue) => fieldValue.field === fieldId);
    if (!updatedValue) return;
    updatedValue.value = fieldList.map((subStructData: { uid: string; fields: Record<string, FieldData<unknown>> }) => subStructData.uid);
    await this.dataService.updateDataInstance(parentDataInstance);

    // Delete subfield data instance
    const instance = await this.dataService.getDataInstance(subStructData.uid);
    if (!instance) throw new Error('Subfield data instance not found');

    await this.dataService.deleteDataInstance(instance);
  }

  // /* METHODS FOR MOVING FIELDS IN ARRAYS */
  isLastField(field: SimpleStructInstance, fieldList: SimpleStructInstance[]) {
    return fieldList.indexOf(field) == fieldList.length - 1;
  }

  isFirstField(field: SimpleStructInstance, fieldList: SimpleStructInstance[]) {
    return fieldList.indexOf(field) == 0;
  }

  moveFieldUp(field: SimpleStructInstance, fieldList: SimpleStructInstance[], dataInstance: DataInstance, fieldId: string) {
    const index = fieldList.indexOf(field);
    this.moveField(index, index - 1, fieldList, dataInstance, fieldId);
  }

  moveFieldDown(field: SimpleStructInstance, fieldList: SimpleStructInstance[], dataInstance: DataInstance, fieldId: string) {
    const index = fieldList.indexOf(field);
    this.moveField(index, index + 1, fieldList, dataInstance, fieldId);
  }

  moveField(from: number, to: number, fieldList: SimpleStructInstance[], dataInstance: DataInstance, fieldId: string) {
    // Remove `from` item and store it
    const f = fieldList.splice(from, 1)[0];
    // insert stored item into position `to`
    fieldList.splice(to, 0, f);

    // Update the data instance
    const updatedValue = dataInstance.fieldValues.find((fieldValue) => fieldValue.field === fieldId);
    if (updatedValue) {
      updatedValue.value = fieldList.map((field) => field.uid);
      this.dataService.updateDataInstance(dataInstance).then();
      this.showAlert(fieldId);
    }
  }
}
