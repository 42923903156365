import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DynamicFieldComponent } from '../../dynamic-fields/dynamic-field.component';
import fieldData, { FieldData } from '../../../models/data/FieldData';
import { DataInstance } from '../../../models/data/DataInstance';
import { DynamicFieldObject } from '../../dynamic-fields/dynamic-field.object';
import { Field } from '../../../models/schema/Field';
import { filter, firstValueFrom, lastValueFrom, Subscription } from 'rxjs';
import { DynamicFieldService } from '../../../_services/data-management/dynamic-field.service';
import { DataService } from '../../../_services/data-management/data.service';
import { ActivatedRoute } from '@angular/router';
import { LoadingScreenService } from '../../../_services/UI-elements/loading-screen.service';
import { environment } from '../../../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileMeta } from '../../../models/data/FileMeta';
import { HTTPRequestService } from '../../../_services/data-management/HTTP-request.service';

@Component({
  selector: 'app-text-with-audio',
  templateUrl: './text-with-audio.component.html',
  styleUrl: './text-with-audio.component.scss',
})
export class TextWithAudioComponent implements OnInit, OnDestroy, DynamicFieldComponent<FieldData<string | string[]> | undefined> {
  @Input({
    transform: (value: FieldData<string | string[] | unknown>) => fieldData.transform(value),
  })
  data: FieldData<string> | undefined;

  @Input() resourceStructType?: string;
  @Input() currentResourceUid?: string;

  @ViewChild('audioModal') audioModal!: ElementRef;

  currentResource?: DataInstance;
  currentResourceFieldComponents: DynamicFieldObject[] = [];
  field?: Field;
  currentFileMeta: FileMeta | undefined;

  routeSub?: Subscription;

  constructor(
    private fieldFactory: DynamicFieldService,
    private dataService: DataService,
    private route: ActivatedRoute,
    private loadingScreenService: LoadingScreenService,
    private modalService: NgbModal,
    private httpRequestService: HTTPRequestService,
  ) {}

  async ngOnInit() {
    await this.loadingScreenService.show(async () => {
      await firstValueFrom(this.fieldFactory.dynamicFieldServiceInitialized.pipe(filter(Boolean)));
      // If this component is not used as a child component,
      // we need to get the resourceStructType and currentResourceUid from the route
      if (!this.data && (!this.resourceStructType || !this.currentResourceUid)) {
        this.routeSub = this.route.params.subscribe((params) => {
          this.resourceStructType = params['resource'];
          this.currentResourceUid = params['resourceUid'];
          this.refreshData().then();
        });
      }
      this.refreshData().then();
    });
  }

  ngOnDestroy() {
    this.routeSub?.unsubscribe();
  }

  async refreshData() {
    if (this.data && this.data.value) {
      const instance = await this.dataService.getDataInstance(this.data.value);
      if (!instance) return console.warn(`Instance ${this.data.value} not found`);

      this.resourceStructType = instance.dataType;
      this.currentResourceUid = instance.uid;
    }

    if (this.resourceStructType && !this.currentResourceUid) {
      // If the resource struct type is set, but the resource uid is not, we need to create a new resource, but the user has to do that
      console.warn('Resource uid not found');
      return;
    }

    if (!this.resourceStructType || !this.currentResourceUid) {
      console.warn(`Resource struct type (${this.resourceStructType}) or resource uid (${this.currentResourceUid}) not found`);
      return;
    }

    this.currentResource = await this.dataService.getDataInstanceFromDB(
      environment.defaultGame,
      environment.dataPackage,
      this.resourceStructType,
      this.currentResourceUid,
    );

    // Get the field components for the current resource
    const fieldComponentsMap = this.fieldFactory.getDynamicFieldObjects(
      this.currentResource.fieldValues,
      this.currentResource.uid,
      this.resourceStructType,
      true,
    );

    // Since the fieldComponentsMap is a map, we need to convert it to a list.
    // (This is due to technical issues in the activity component, see the comments there).
    this.currentResourceFieldComponents = Object.values(fieldComponentsMap);

    // Sort the fields by position
    if (this.currentResourceFieldComponents.some((field) => field.data.fieldEditor)) {
      this.currentResourceFieldComponents.sort((a, b) => {
        // Provide a default position value when fieldEditor is undefined
        const positionA = a.data.fieldEditor?.position ?? Number.MAX_SAFE_INTEGER;
        const positionB = b.data.fieldEditor?.position ?? Number.MAX_SAFE_INTEGER;
        return positionA - positionB;
      });
    }

    if (this.currentResourceFieldComponents.length >= 2 && this.currentResourceFieldComponents[1]?.data?.value) {
      this.currentFileMeta = await lastValueFrom(
        this.httpRequestService.getFileMetaOfDataInstance(
          this.dataService.currentGameId,
          this.dataService.currentDataPackage,
          <string>this.currentResourceFieldComponents[1]?.data?.value,
        ),
      );
    }
  }

  addStructInstance() {
    if (!this.data) throw new Error('Data not found');

    this.dataService.getDataInstance(this.data.dataInstanceUid).then((data) => {
      if (!data || !this.data) throw new Error('Data not found');

      this.field = this.dataService.getField(this.data.fieldId, data.dataType);
      if (!this.field) throw new Error('Field not found');

      const start = this.field.type.indexOf('<') + 1;
      const end = this.field.type.lastIndexOf('>');
      const result = this.field.type.slice(start, end);

      this.dataService.initStruct(result).then((dataInstance) => {
        if (!dataInstance || !this.data) throw new Error('Data not found');

        this.data.value = dataInstance.uid;

        this.dataService.updateFieldValue(this.data.dataInstanceUid, this.data.fieldId, this.data.value).then(() => {
          this.currentResource = dataInstance;
          this.resourceStructType = dataInstance.dataType;

          const fieldComponentsMap = this.fieldFactory.getDynamicFieldObjects(
            dataInstance.fieldValues,
            dataInstance.uid,
            dataInstance.dataType,
            true,
          );
          this.currentResourceFieldComponents = Object.values(fieldComponentsMap);
        });
      });
    });
  }

  openAudioModal() {
    this.refreshData().then((_value) => {
      const modalRef = this.modalService.open(this.audioModal);
      modalRef.result.finally(() => {
        this.refreshData();
      });
    });
  }

  isAudioFilledIn(): boolean {
    const audioField = this.currentResourceFieldComponents[1]?.data;
    return !!audioField && !!audioField.value;
  }

  getAudioName(): string {
    return this.currentFileMeta?.name ?? 'No audio selected';
  }
}
