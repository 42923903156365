<div (cdkDropListDropped)="onMoveDataInstanceOrder($event)" cdkDropList>
  <div *ngFor="let instance of instances" cdkDrag class="inline-list-item">
    <app-variable-comparison
      (deleteVariableComparison)="deleteInstance(instance)"
      *ngIf="instance.dataType === 'VariableComparison'"
      [instance]="instance"
      [showDelete]="true"
    />

    <app-set-variable-field
      (deleteSetVariable)="deleteInstance(instance)"
      *ngIf="instance.dataType === 'SetVariableAction'"
      [instance]="instance"
    />

    <app-struct-instance-editor
      (deleteStructInstance)="deleteInstance(instance)"
      *ngIf="instance.dataType !== 'VariableComparison' && instance.dataType !== 'SetVariableAction'"
      [currentResourceUid]="instance.uid"
      [resourceStructType]="instance.dataType"
      [showDeleteButton]="true"
    />

    <div cdkDragHandle class="btn second-header-button">
      <i class="bi bi-arrows-move"></i>
    </div>
  </div>
</div>
<div class="d-flex justify-content-center gap-1 my-1">
  <!--  If we have few choices, show buttons-->
  <div *ngIf="structTypes.length <= 3" class="d-flex gap-1">
    <ng-container *ngFor="let structType of structTypes; let i = index">
      <button (click)="addInstance(structType)" class="btn btn-primary">
        <i class="bi bi-plus-circle me-1"></i> Add {{ structType | prettifyName }}
      </button>
    </ng-container>
  </div>
  <!--  If we have many choices, show a dropdown-->
  <div *ngIf="structTypes.length > 3">
    <select (change)="onStructTypeSelected($event)" class="form-select">
      <option disabled selected value="">Add {{ typeName | prettifyName }}...</option>
      <option *ngFor="let structType of structTypes"
              [value]="structType">{{ structType | replace:typeName:''  | prettifyName }}
      </option>
    </select>
  </div>
</div>

<!-- struct type selector Modal -->
<ng-template
  #structTypeSelectorModal
  aria-hidden="true"
  aria-labelledby="structTypeSelectorLabel"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  let-modal
  tabindex="-1"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="structTypeSelectorModalLabel">Select struct type</h5>
        <button (click)="modal.dismiss()" aria-label="Close" class="btn-close" data-bs-dismiss="modal"
                type="button"></button>
      </div>
      <div class="modal-body">
        <select #structTypeSelector>
          <option *ngFor="let structType of structTypes" [value]="structType">{{ structType | prettifyName }}</option>
        </select>
      </div>
      <div class="modal-footer">
        <button
          (click)="addInstance(structTypeSelector.value); modal.dismiss()"
          class="btn btn-primary"
          data-bs-dismiss="modal"
          type="button"
        >
          Add
        </button>
      </div>
    </div>
  </div>
</ng-template>
