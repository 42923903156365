<section class="min-vh-100 gradient-custom">
  <nav class="navbar navbar-expand-lg bg-primary sticky-top py-1">
    <div class="container-fluid">
      <a class="navbar-brand me-1" routerLink='/'>
        <img alt="CAS Logo" height="30" ngSrc="assets/images/orange-slice-30px.webp" width="30" />
      </a>

      <button
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
        class="navbar-toggler"
        data-bs-target="#navbarSupportedContent"
        data-bs-toggle="collapse"
        type="button"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <!--        All resource structs-->
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li *ngFor="let resourceStruct of resourceStructs" class="nav-item">
            <a [routerLink]="resourceStruct" class="nav-link">{{ resourceStruct }}</a>
          </li>
        </ul>

        <!--        Dark mode toggle-->
        <button (click)="toggleDarkMode()" class="btn btn-primary ms-auto cursor-pointer border-0">
          <i class="fas fa-moon"></i>
        </button>

        <!--        Search-->
        <button (click)="showSearch()" class="btn btn-link ms-2 p-0 cursor-text border-0">
          <input
            class="form-control search-input"
            placeholder="Search (Ctrl + K)"
            type="text"
          />
        </button>
      </div>
    </div>
  </nav>

  <app-search-popover />
  <router-outlet />

  <div class="build-info text-muted">
    <p *ngFor="let line of versions" style="margin: 0">
      {{ line }}
    </p>
  </div>
</section>
