import { Component, Input, OnInit } from '@angular/core';
import { FieldData } from '../../../models/data/FieldData';
import { Resource } from '../../../models/data/Resource';
import { DataService } from '../../../_services/data-management/data.service';
import { AlertService } from '../../../_services/UI-elements/alert-service';
import { BootstrapClass } from '../../../models/types/BootstrapClass';
import { DataInstance } from '../../../models/data/DataInstance';
import { deepCopy } from '../../../_services/utils';

@Component({
  selector: 'app-enum-instance-editor',
  templateUrl: './enum-instance-editor.component.html',
  styleUrls: ['./enum-instance-editor.component.scss'],
})
export class EnumInstanceEditorComponent implements OnInit {
  @Input() data: FieldData<string> | undefined;

  choices: Resource[] = [];
  currentChoice: string | undefined;
  currentChoiceDataInstance: DataInstance | undefined;
  enumType = '';

  constructor(
    private dataService: DataService,
    private alertService: AlertService,
  ) {}

  async ngOnInit() {
    if (!this.data) {
      console.error('Data or select type not found');
      return;
    }
    const instance = await this.dataService.getDataInstance(this.data.dataInstanceUid);
    if (!instance) throw new Error('Data instance not found');

    this.enumType = this.data.fieldId;

    // In order to get the enum type, we need to get the field first, which needs the StructTypeId
    const structTypeId = instance.dataType;
    // Get the enum type from the data field
    const field = this.dataService.getField(this.data.fieldId, structTypeId);

    const enumTypeId = this.dataService.getTypeIdFromRefType(field.type);
    // Get the enum type options
    const enumOptions = this.dataService.getEnumTypeResource(enumTypeId).slice();
    enumOptions.forEach((enumOption) => {
      const choiceDescription = this.dataService.getStructType(enumOption.value).description;
      if (!choiceDescription) this.choices.push({ name: `${enumOption.name}`, value: enumOption.value });
      else this.choices.push({ name: `${enumOption.name} - ${choiceDescription}`, value: enumOption.value });
    });

    // If the data value is not set, set it to the default value if any
    if (this.data.value) {
      this.currentChoice = await this.dataService.getDataInstance(this.data.value).then((dataInstance) => {
        this.currentChoiceDataInstance = dataInstance;
        return dataInstance.dataType;
      });
    }
  }

  async update() {
    if (!this.data) throw new Error('Data not found');
    if (!this.currentChoice) throw new Error('No enum type selected');

    // Create a new instance
    const instance = await this.dataService.initStruct(this.currentChoice);

    // Deepcopy the data to trigger the ngOnChanges and update the selected struct and show its fields
    const newData = deepCopy(this.data);
    newData.value = instance.uid;
    this.data = deepCopy(newData);
    await this.dataService.updateFieldValue(this.data.dataInstanceUid, this.data.fieldId, this.data.value);

    // Delete the old instance if it exists
    if (this.currentChoiceDataInstance) {
      try {
        await this.dataService.deleteDataInstance(this.currentChoiceDataInstance);
      } catch (e) {
        console.warn('Failed to delete old instance', e);
      }
    }
    this.showAlert();
    this.currentChoiceDataInstance = instance;
  }

  showAlert() {
    if (!this.data) return;
    this.alertService.showAlert('Updated ' + this.data.name + '...', BootstrapClass.INFO);
  }
}
