import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { sleep } from '../utils';

@Injectable({
  providedIn: 'root',
})
export class LoadingScreenService {
  private showPromises: Record<string, Promise<unknown> | unknown> = {};

  private shown = new BehaviorSubject<boolean>(false);
  public readonly shown$ = this.shown.asObservable();

  public async show<T = void>(data: () => Promise<T> | T) {
    const id = crypto.randomUUID();

    try {
      this.showPromises[id] = data();

      this.update();
      return await this.showPromises[id];
    } finally {
      delete this.showPromises[id];
      await sleep(50);
      this.update();
    }
  }

  private update() {
    this.shown.next(Object.keys(this.showPromises).length > 0);
  }
}
